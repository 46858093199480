<template>
  <Modal :options="{width: '40vw', type: 'danger'}">
    <form @submit.prevent="cambiar_estatus">
      <div class="title">Solicitud no viable</div>
      <div class="body">
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-3">Razón no viable</label>
          <div class="col-sm-9"><textarea v-model="razon"  name="razon_no_viable" id="razon_no_viable" cols="10" rows="10" class="form-control razon_no_viable"></textarea></div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2">Marcar como no viable</button>
            <button class="btn btn-danger" type="button" @click="cancelar">Cancelar</button></div>
        </div>
      </div>
    </form>
  </Modal>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'

  export default {
    components: {
      Modal
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
            ,solicitud: null
          }
        }
      }
    }
    ,data() {
      return {
        razon: null
      }
    }
    ,methods: {
      async cambiar_estatus() {
        try {
          if (!this.razon)
            return this.$helper.showMessage('Error','Debes definir una razón para marcar como No viable','error','alert');

          let res = (await apiSolicitudes.cambiar_estatus(this.solicitud.id, {estatus: 0, razon_inviable: this.razon})).data;
          this.$log.info('res', res);

          this.$router.push({name: 'formalizacion_v2_usuarios_solicitudes'});
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cancelar() {
        this.$emit('cancelar');
      }

    }
  }
</script>

<style lang="scss">
  .razon_no_viable {
    height: 150px !important;
    resize: none;
  }
</style>
<template>
  <div>
    <div class="row">
      <div class="col-sm-10 offset-sm-1">
        <div class="row">
          <h1 class="col-sm-12">Documentación completa</h1>
        </div>
        
        <div class="row mb-4">
          <div class="col-sm-12">Indica por favor cuales son los siguientes documentos.</div>
        </div>

        <div class="row mb-4">
          <label for="documento_valuacion" class="col-form-label col-sm-2">Valuación</label>
          <div class="col-sm-10">
            <select v-model="documento_valuacion" name="documento_valuacion" id="documento_valuacion" class="form-control">
              <option v-for="documento in valija.documentos" :key="documento.id" :value="buscar_nombre(documento.tipo_documento)">{{ buscar_nombre(documento.tipo_documento) }}</option>
            </select>
          </div>
        </div>
        <div class="row mb-4">
          <label for="documento_clg" class="col-form-label col-sm-2">CLG</label>
          <div class="col-sm-10">
            <select v-model="documento_clg" name="documento_clg" id="documento_clg" class="form-control">
              <option v-for="documento in valija.documentos" :key="documento.id" :value="buscar_nombre(documento.tipo_documento)">{{ buscar_nombre(documento.tipo_documento) }}</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-warning mr-2" @click="$emit('retroceder')">Retroceder etapa</button>
            <button class="btn btn-danger mr-2" @click="no_viable=true">No viable</button>
            <button class="btn btn-success" @click="avanzar">Documentación completa</button>
          </div>
        </div>
      </div>
    </div>

    <NoViable v-if="no_viable" @cancelar="no_viable=false" :solicitud="solicitud"/>
  </div>
</template>

<script type="text/javascript">
  import NoViable from '@/apps/formalizacion/Componentes/NoViable'

  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'
  import apiValija from '@/apps/formalizacion/api/v2/valija'

  export default {
    components: {
      NoViable
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
            ,solicitud: null
          }
        }
      }
    }
    ,data() {
      return {
        no_viable: false
        ,valija: {
          id: null
          ,documentos: []
        }
        ,catalogo: {
          opciones: []
        }
        ,documento_valuacion: null
        ,documento_clg: null
      }
    }
    ,mounted() {
      this.obtener_documentos();
    }
    ,methods: {
      async obtener_documentos() {
        try {
          this.catalogo = (await apiValija.obtener_catalogo('tipos_archivos')).data;
          this.valija = (await apiValija.listar_documentos(this.solicitud.solicitud)).data;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,buscar_nombre(tipo) {
        let nombre;

        for(let i=0; i<this.catalogo.opciones.length; i++) {
          if (this.catalogo.opciones[i].valor == tipo) {
            nombre = this.catalogo.opciones[i].nombre;
            i = this.catalogo.opciones.length;
          }
        }

        return nombre
      }
      ,async avanzar() {
        try {
          let res = (await apiSolicitud.agregar_observacion(this.solicitud.id, {
            observacion: 'El notario indico que los archivos son los siguientes: <ul><li>'+this.documento_valuacion+'</li><li>'+this.documento_clg+'</li></ul>'
            ,tipo: 0
          })).data;
          this.$log.info('res', res);

          this.$emit('avanzar');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>